// Module
var code = `<span class="token keyword">import</span> Map <span class="token keyword">from</span> <span class="token string">"ol/Map.js"</span><span class="token punctuation">;</span>
<span class="token keyword">import</span> <span class="token constant">OSM</span> <span class="token keyword">from</span> <span class="token string">"ol/source/OSM.js"</span><span class="token punctuation">;</span>
<span class="token keyword">import</span> TileLayer <span class="token keyword">from</span> <span class="token string">"ol/layer/Tile.js"</span><span class="token punctuation">;</span>
<span class="token keyword">import</span> View <span class="token keyword">from</span> <span class="token string">"ol/View.js"</span><span class="token punctuation">;</span>
<span class="token keyword">import</span> VectorTileLayer <span class="token keyword">from</span> <span class="token string">"ol/layer/VectorTile"</span><span class="token punctuation">;</span>
<span class="token keyword">import</span> TileDebug <span class="token keyword">from</span> <span class="token string">"ol/source/TileDebug"</span><span class="token punctuation">;</span>
<span class="token keyword">import</span> <span class="token punctuation">{</span> fromLonLat <span class="token punctuation">}</span> <span class="token keyword">from</span> <span class="token string">"ol/proj"</span><span class="token punctuation">;</span>

<span class="token keyword">import</span> <span class="token punctuation">{</span> importMBTiles<span class="token punctuation">,</span> MBTilesVectorSource <span class="token punctuation">}</span> <span class="token keyword">from</span> <span class="token string">"ol-mbtiles"</span><span class="token punctuation">;</span>
<span class="token keyword">import</span> <span class="token punctuation">{</span> styleTown <span class="token punctuation">}</span> <span class="token keyword">from</span> <span class="token string">"../style"</span><span class="token punctuation">;</span>

<span class="token keyword">export</span> <span class="token keyword">default</span> <span class="token keyword">async</span> <span class="token keyword">function</span> <span class="token punctuation">(</span><span class="token punctuation">)</span> <span class="token punctuation">{</span>
  <span class="token keyword">return</span> <span class="token keyword">new</span> <span class="token class-name">Map</span><span class="token punctuation">(</span><span class="token punctuation">{</span>
    target<span class="token operator">:</span> <span class="token string">"map"</span><span class="token punctuation">,</span>
    layers<span class="token operator">:</span> <span class="token punctuation">[</span>
      <span class="token keyword">new</span> <span class="token class-name">TileLayer</span><span class="token punctuation">(</span><span class="token punctuation">{</span>
        source<span class="token operator">:</span> <span class="token keyword">new</span> <span class="token class-name"><span class="token constant">OSM</span></span><span class="token punctuation">(</span><span class="token punctuation">)</span><span class="token punctuation">,</span>
      <span class="token punctuation">}</span><span class="token punctuation">)</span><span class="token punctuation">,</span>
      <span class="token keyword">new</span> <span class="token class-name">TileLayer</span><span class="token punctuation">(</span><span class="token punctuation">{</span>
        source<span class="token operator">:</span> <span class="token keyword">new</span> <span class="token class-name">TileDebug</span><span class="token punctuation">(</span><span class="token punctuation">)</span><span class="token punctuation">,</span>
      <span class="token punctuation">}</span><span class="token punctuation">)</span><span class="token punctuation">,</span>
      <span class="token keyword">new</span> <span class="token class-name">VectorTileLayer</span><span class="token punctuation">(</span><span class="token punctuation">{</span>
        source<span class="token operator">:</span> <span class="token keyword">new</span> <span class="token class-name">MBTilesVectorSource</span><span class="token punctuation">(</span>
          <span class="token keyword">await</span> <span class="token function">importMBTiles</span><span class="token punctuation">(</span><span class="token punctuation">{</span>
            url<span class="token operator">:</span> <span class="token string">"https://velivole.b-cdn.net/mbtiles/place/0.mbtiles"</span><span class="token punctuation">,</span>
          <span class="token punctuation">}</span><span class="token punctuation">)</span><span class="token punctuation">,</span>
        <span class="token punctuation">)</span><span class="token punctuation">,</span>
        style<span class="token operator">:</span> <span class="token function">styleTown</span><span class="token punctuation">.</span><span class="token function">bind</span><span class="token punctuation">(</span><span class="token keyword">null</span><span class="token punctuation">,</span> <span class="token number">0</span><span class="token punctuation">)</span><span class="token punctuation">,</span>
        renderBuffer<span class="token operator">:</span> <span class="token number">1024</span><span class="token punctuation">,</span>
        minZoom<span class="token operator">:</span> <span class="token number">6</span><span class="token punctuation">,</span>
      <span class="token punctuation">}</span><span class="token punctuation">)</span><span class="token punctuation">,</span>
      <span class="token keyword">new</span> <span class="token class-name">VectorTileLayer</span><span class="token punctuation">(</span><span class="token punctuation">{</span>
        source<span class="token operator">:</span> <span class="token keyword">new</span> <span class="token class-name">MBTilesVectorSource</span><span class="token punctuation">(</span>
          <span class="token keyword">await</span> <span class="token function">importMBTiles</span><span class="token punctuation">(</span><span class="token punctuation">{</span>
            url<span class="token operator">:</span> <span class="token string">"https://velivole.b-cdn.net/mbtiles/place/1.mbtiles"</span><span class="token punctuation">,</span>
          <span class="token punctuation">}</span><span class="token punctuation">)</span><span class="token punctuation">,</span>
        <span class="token punctuation">)</span><span class="token punctuation">,</span>
        style<span class="token operator">:</span> <span class="token function">styleTown</span><span class="token punctuation">.</span><span class="token function">bind</span><span class="token punctuation">(</span><span class="token keyword">null</span><span class="token punctuation">,</span> <span class="token number">1</span><span class="token punctuation">)</span><span class="token punctuation">,</span>
        renderBuffer<span class="token operator">:</span> <span class="token number">256</span><span class="token punctuation">,</span>
        minZoom<span class="token operator">:</span> <span class="token number">8</span><span class="token punctuation">,</span>
      <span class="token punctuation">}</span><span class="token punctuation">)</span><span class="token punctuation">,</span>
      <span class="token keyword">new</span> <span class="token class-name">VectorTileLayer</span><span class="token punctuation">(</span><span class="token punctuation">{</span>
        source<span class="token operator">:</span> <span class="token keyword">new</span> <span class="token class-name">MBTilesVectorSource</span><span class="token punctuation">(</span>
          <span class="token keyword">await</span> <span class="token function">importMBTiles</span><span class="token punctuation">(</span><span class="token punctuation">{</span>
            url<span class="token operator">:</span> <span class="token string">"https://velivole.b-cdn.net/mbtiles/place/2.mbtiles"</span><span class="token punctuation">,</span>
          <span class="token punctuation">}</span><span class="token punctuation">)</span><span class="token punctuation">,</span>
        <span class="token punctuation">)</span><span class="token punctuation">,</span>
        style<span class="token operator">:</span> <span class="token function">styleTown</span><span class="token punctuation">.</span><span class="token function">bind</span><span class="token punctuation">(</span><span class="token keyword">null</span><span class="token punctuation">,</span> <span class="token number">2</span><span class="token punctuation">)</span><span class="token punctuation">,</span>
        minZoom<span class="token operator">:</span> <span class="token number">10</span><span class="token punctuation">,</span>
      <span class="token punctuation">}</span><span class="token punctuation">)</span><span class="token punctuation">,</span>
    <span class="token punctuation">]</span><span class="token punctuation">,</span>
    view<span class="token operator">:</span> <span class="token keyword">new</span> <span class="token class-name">View</span><span class="token punctuation">(</span><span class="token punctuation">{</span>
      center<span class="token operator">:</span> <span class="token function">fromLonLat</span><span class="token punctuation">(</span><span class="token punctuation">[</span><span class="token number">6</span><span class="token punctuation">,</span> <span class="token number">45</span><span class="token punctuation">]</span><span class="token punctuation">)</span><span class="token punctuation">,</span>
      zoom<span class="token operator">:</span> <span class="token number">8</span><span class="token punctuation">,</span>
    <span class="token punctuation">}</span><span class="token punctuation">)</span><span class="token punctuation">,</span>
  <span class="token punctuation">}</span><span class="token punctuation">)</span><span class="token punctuation">;</span>
<span class="token punctuation">}</span>
`;
// Exports
export default code;